<template>
  <div class="invitations" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('invitations.title') }}</h1>
    <div v-if="invitationForm.isVisible">
      <b-form @submit="onSubmit" @reset="onReset">
        <FormButtons @goBack="goBack" :has-delete-button="!!invitationForm.formCache.invitationId" @delete="deleteInvitation(invitationForm.form)"/>
        <InvitationForm :invitation="invitationForm" :action="invitationForm.action" :view="view"/>
        <CommentCard :targetId="'invite-' + invitationForm.form.invitationId.toString()" :new-comment.sync="newComment" :showTable="invitationForm.action === 'edit'" :view="view"/>
      </b-form>
    </div>
    <div v-else class="overflow-auto px-2 px-md-5">
      <div class="row justify-content-between justify-content-lg-start mt-lg-3">
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="addInvitation" variant="success">
            <b-icon-plus/> {{ $t('comp.invitations.addInvitation.label') }}
          </b-button>
        </div>
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('pending')" variant="primary">
            <b-icon-funnel-fill v-if="pendingFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showPendingOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('accepted')" variant="primary">
            <b-icon-funnel-fill v-if="acceptedFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showAcceptedOnly.label') }}
          </b-button>
        </div>
        <div class="col-6 col-lg-auto mb-3 pr-lg-0">
          <b-button class="w-100" @click="showOnly('otherstates')" variant="primary">
            <b-icon-funnel-fill v-if="otherStatesFilterSet"/> <b-icon-funnel v-else/>
            {{ $t('comp.invitations.showOtherStatesOnly.label') }}
          </b-button>
        </div>
      </div>
      <TableHeader @refresh="refresh" :filter.sync="filter" :per-page.sync="perPage" :current-page.sync="currentPage"
                   :rows="rows" :total-rows="totalRows" :searchables="searchables" table-id="invitations-table"/>
      <b-table responsive id="invitations-table" ref="invitations-table" :busy.sync="isBusy" :fields="fields" :per-page="perPage"
               :current-page="currentPage" :filter="filter" :items="invitationItemProvider" :sort-by.sync="sortBy"
               :sort-desc.sync="sortDesc" small striped hover>
        <template v-slot:cell(actions)="data">
          <b-button @click="editInvitation(data.item)" :title="$t('comp.invitations.edit.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-pencil class="mr-1" variant="primary"/>
          </b-button>
          <b-button @click="deleteInvitation(data.item)" :title="$t('comp.invitations.delete.label')" variant="light"
                    size="sm" class="mr-1">
            <b-icon-trash class="mr-1" variant="danger"/>
          </b-button>
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import { i18nMixin } from '@/mixins/i18n.mixin'
import { invitationFilterMixin } from '@/mixins/tableFilter.mixin'
import { invitationServiceForAdminView } from '@/services/invitation.service'
import { commentServiceForAdminView } from '@/services/comment.service'
import TableHeader from '@/components/generic/helper/TableHeader'
import FormButtons from '@/components/generic/helper/FormButtons'
import InvitationForm from '@/components/generic/form/InvitationForm'
import CommentCard from '@/components/generic/helper/CommentCard'

export default {
  name: 'Invitations',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin, invitationFilterMixin],
  components: {
    InvitationForm,
    TableHeader,
    FormButtons,
    CommentCard
  },
  data () {
    return {
      view: 'admin',
      newComment: '',
      changes: [],
      // Table
      isBusy: false,
      perPage: 10,
      currentPage: 1,
      sortBy: 'createdAt',
      sortDesc: true,
      rows: 0,
      totalRows: 0,
      filter: '',
      // Form
      invitationForm: {
        action: null,
        form: null,
        formCache: null,
        isVisible: false,
        sendMail: true,
        createPolicy: true,
        emptyForm: {
          invitationId: '',
          forMail: null,
          forProject: null,
          forValidity: null,
          message: '',
          state: 'pending'
        }
      }
    }
  },
  computed: {
    fields () {
      const fields = [
        { key: 'actions', label: this.$i18n.t('actions.label'), sortable: false, searchable: false },
        { key: 'invitationId' },
        { key: 'forMail' },
        { key: 'forProject', select: true },
        { key: 'forValidity', date: true, searchable: false },
        { key: 'state', state: true },
        { key: 'account' },
        { key: 'supervisor' },
        { key: 'createdBy', select: true },
        { key: 'createdAt', date: true, searchable: false }
      ]

      _.each(fields, (field) => {
        if (field.sortable == null) {
          field.sortable = true
        }
        if (field.searchable == null) {
          field.searchable = true
        }
        if (field.label == null) {
          field.label = this.$i18n.t(`comp.invitations.${field.key}.label`)
        }
        if (field.date) {
          field.formatter = (value, key, item) => {
            return value ? moment(value).format('YYYY-MM-DD HH:mm') : ''
          }
          field.sortByFormatted = true
        }
        if (field.select) {
          field.sortKey = field.key + '.label'
          field.formatter = (value, key, item) => {
            if (key === 'forProject') {
              return _.has(value, 'label') ? `${value.label} (${value.key})` : ''
            } else {
              return _.has(value, 'label') ? value.label : ''
            }
          }
          field.sortByFormatted = true
        }
        if (field.state) {
          field.formatter = (value) => {
            return this.$i18n.t(`states.${value}.label`)
          }
        }
      })
      return fields
    },
    searchables () {
      const localized = []
      this.fields.forEach((field) => {
        if (field.searchable === true) localized.push(this.$i18n.t(`comp.invitations.${field.key}.label`))
      })
      return localized
    }
  },
  created () {
    invitationServiceForAdminView.count({ filter: this.filter }).then((response) => (this.rows = response))
    invitationServiceForAdminView.count().then((response) => (this.totalRows = response))
  },
  methods: {
    refresh () {
      invitationServiceForAdminView.count().then((response) => (this.totalRows = response))
      if (this.$refs['invitations-table']) {
        this.$refs['invitations-table'].refresh()
      }
    },
    addInvitation () {
      this.invitationForm.action = 'add'
      this.invitationForm.formCache = _.cloneDeep(this.invitationForm.emptyForm)
      this.invitationForm.form = _.cloneDeep(this.invitationForm.formCache)
      this.invitationForm.isVisible = true
    },
    editInvitation (item) {
      this.invitationForm.action = 'edit'
      this.invitationForm.formCache = _.cloneDeep(item)
      this.invitationForm.form = _.cloneDeep(this.invitationForm.formCache)
      this.invitationForm.isVisible = true
    },
    onSubmit (evt) {
      evt.preventDefault()
      if (this.invitationForm.action === 'add') {
        invitationServiceForAdminView.create(this.invitationForm.form, { sendMail: this.invitationForm.sendMail, createPolicy: this.invitationForm.createPolicy }).then(
          response => {
            // Save initial comment
            this.logChanges(false, `invite-${response.invitationId}`)
            this.makeToast(
              this.$i18n.t('created.text', { id: response.invitationId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            if (this.invitationForm.sendMail && response.mailStatus === 500) {
              this.makeToast(
                this.$i18n.t('error.text', { status: response.mailStatus, message: 'Internal Server Error: Invitation created, but mail could not be sent!', id: response.invitationId }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            } else if (this.invitationForm.sendMail) {
              this.makeToast(
                this.$i18n.t('mailsend.text', { id: response.invitationId, formail: response.forMail }),
                this.$i18n.t('mailsend.title'),
                'success'
              )
            }
            this.goBack()
            this.refresh()
          }
        ).catch(
          error => {
            if (error.status === 409) {
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: this.$i18n.t('invitations.duplicate.error'), id: error.request.response }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            } else {
              const parsed = JSON.parse(error.request.response)
              this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: parsed.error, id: this.invitationForm.form.forMail }),
                this.$i18n.t('result.error.title'),
                'danger'
              )
            }
          }
        )
      } else if (this.invitationForm.action === 'edit') {
        invitationServiceForAdminView.update(this.invitationForm.form).then(
          response => {
            // Log changes if any or save comment
            this.logChanges(true, `invite-${this.invitationForm.form.invitationId}`)
            this.makeToast(
              this.$i18n.t('updated.text', { id: this.invitationForm.form.invitationId, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
            this.goBack()
          }
        ).catch(
          error => this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.invitationForm.form.invitationId }),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        )
      }
    },
    onReset (evt) {
      evt.preventDefault()
      // Reset our form values
      this.invitationForm.form = _.cloneDeep(this.invitationForm.formCache)
      // Trick to reset/clear native browser form validation state
      // this.invitationForm.isVisible = false
      // this.$nextTick(() => {
      //  this.invitationForm.isVisible = true
      // })
      this.newComment = ''
      this.changes = []
    },
    deleteInvitation (invitation) {
      this.$bvModal.msgBoxConfirm(this.$i18n.t('sure.question'), {
        okVariant: 'danger',
        okTitle: this.$i18n.t('confirm.delete.label'),
        cancelTitle: this.$i18n.t('no.label')
      })
        .then(value => {
          if (value === true) {
            invitationServiceForAdminView.delete(invitation.invitationId).then(
              (response) => {
                this.makeToast(
                  this.$i18n.t('deleted.text', { id: invitation.invitationId, code: response.code }),
                  this.$i18n.t('result.success.title'),
                  'success')
                if (this.invitationForm.action === 'edit') { this.goBack() }
                this.refresh()
              }
            ).catch(
              error => this.makeToast(
                this.$i18n.t('error.text', { status: error.status, message: error.message, id: invitation.invitationId }),
                this.$i18n.t('result.error.title'),
                'danger')
            )
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    goBack () {
      this.invitationForm.isVisible = false
      this.invitationForm.sendMail = true
      this.invitationForm.form = null
      this.invitationForm.formCache = null
      this.invitationForm.action = null
      this.newComment = ''
      this.changes = []
    },
    invitationItemProvider (ctx) {
      return invitationServiceForAdminView.list(ctx).then((data) => {
        this.rows = data.count
        return data.items
      }).catch(error => {
        console.log(error)
        return []
      })
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    },
    logChanges (forEdit, targetId) {
      // Collect Changes only on Edit
      if (forEdit === true) {
        Object.keys(this.invitationForm.form).forEach(key => {
          if (!_.isEqual(this.invitationForm.form[key], this.invitationForm.formCache[key])) {
            const newChange = { field: key, before: this.invitationForm.formCache[key], after: this.invitationForm.form[key] }
            this.changes.push(newChange)
          }
        })
      }
      // Log Changes if any changes (Edit only) or if comment exists (create/edit)
      if (this.changes.length > 0 || this.newComment !== '') {
        commentServiceForAdminView.create({ targetId: targetId, comment: this.newComment, changes: this.changes }).then(
          response => {
            this.makeToast(
              this.$i18n.t('created.text', { id: 'Log: ' + response.comment, code: response.code }),
              this.$i18n.t('result.success.title'),
              'success'
            )
          }
        ).catch(
          error => {
            this.changes = [] // Reset changes, will be refilled with next submssion
            this.makeToast(
              this.$i18n.t('error.text', { status: error.status, message: error.message, id: this.form.title }),
              this.$i18n.t('result.error.title'),
              'danger'
            )
          }
        )
      }
    }
  }
}
</script>

<style scoped>
</style>
