<template>
  <div class="import" v-show="messagesAvailable">
    <h1 class="sr-only">{{ $t('import.title') }}</h1>

    <b-card>
      <template #header>
        {{ $t('comp.import.note.header') }}
      </template>
      {{ $t('comp.import.note.text') }}
    </b-card>

    <div class="accordion m-5" role="tablist">
      <b-card v-for="entity in Object.keys(importFile)" :key="entity" no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="`entity-${entity}`" variant="light" class="text-left">
            <b-icon-file-earmark-arrow-up/> {{ $t('comp.import.importFor.label', {entity: $t(`comp.import.${entity}.label`)}) }}
            <span class="when-open"><b-icon-arrow-up font-scale="0.75"/></span>
            <span class="when-closed"><b-icon-arrow-down font-scale="0.75"/></span>
          </b-button>
        </b-card-header>
        <b-collapse :id="`entity-${entity}`" accordion="import" role="tabpanel">
          <b-card-body>
            <b-card-text>
              <b-card class="mt-3">
                <template #header>
                  {{ $t('comp.import.uploadFile.label') }}
                </template>
                <b-form-file
                  v-model="importFile[entity]"
                  :state="Boolean(importFile[entity])"
                  accept="application/json"
                  :placeholder="$t('comp.import.fileUpload.placeholder.label')"
                  :drop-placeholder="$t('comp.import.fileUpload.dropPlaceholder.label')"
                  :browse-text="$t('comp.import.fileUpload.browse.label')"
                />
                <b-button @click="parseJson(importFile[entity], entity)" :disabled="!Boolean(importFile[entity])"
                          variant="success" class="mx-1 my-3">
                  {{ $t('comp.import.checkFileUpload.label') }}
                </b-button>
                <b-button @click="clearFileUpload(entity)" variant="warning" class="mx-1 my-3">
                  {{ $t('comp.import.clearFileUpload.label') }}
                </b-button>
                <b-modal :id="`import-json-preview-${entity}`" :title="$t('comp.import.importJson.label')" size="xl"
                         scrollable @hide="clearFileUpload(entity)">
                  <vue-json-pretty :data="importJson[entity]"/>
                  <template #modal-footer>
                    <b-button variant="secondary" @click="hideModalAndClearFileUpload(entity)">
                      {{ $t('comp.import.cancelImport.label') }}
                    </b-button>
                    <b-button variant="success" @click="onSubmit(entity)">
                      {{ $t('comp.import.submitImport.label') }}
                    </b-button>
                  </template>
                </b-modal>
                <b-modal :id="`import-result-${entity}`" :title="$t('comp.import.success.title')" scrollable
                         :ok-title="$t('ok.label')" ok-only size="lg">
                  <ul>
                    <li>
                      <strong>{{ $t('comp.import.hash.label') }}: </strong>
                      <code>{{ importResult[entity].hash }}</code>
                    </li>
                    <li v-if="importResult[entity].nextIndex">
                      <strong>{{ $t('comp.import.nextindex.label') }}: </strong>
                      <code>{{ importResult[entity].nextIndex }}</code>
                    </li>
                    <li>
                      <strong>{{ $t('comp.import.status.label') }}: </strong>
                    </li>
                    <ul>
                      <li>
                        {{ $t('comp.import.note.label') }}: {{ importResult[entity].note }}
                      </li>
                      <li>
                        {{ $t('comp.import.imported.label', {entity: $t(`comp.import.${entity}.label`)}) }}
                        {{ `(${importResult[entity].importedCount})`}}:
                        {{ joinImported(entity) || '–' }}
                      </li>
                      <li>
                        {{ $t('comp.import.ignored.label', {entity: $t(`comp.import.${entity}.label`)}) }}
                        {{ `(${importResult[entity].ignoredCount})`}}:
                        {{ joinIgnored(entity) || '–' }}
                      </li>
                    </ul>
                  </ul>
                </b-modal>
              </b-card>
              <b-card class="mt-3">
                <template #header>
                  {{ $t('comp.import.importTemplate.label') }}
                </template>
                <vue-json-pretty :data="importTemplate[entity]"/>
              </b-card>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { i18nMixin } from '@/mixins/i18n.mixin'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'
import { importServiceForAdminView } from '@/services/import.service'

export default {
  name: 'Import',
  i18n: {
    messages: {}
  },
  mixins: [i18nMixin],
  components: {
    VueJsonPretty
  },
  data () {
    return {
      importFile: {
        users: null,
        accounts: null,
        projects: null
      },
      importJson: {
        users: [],
        accounts: [],
        projects: []
      },
      importResult: {
        users: {
          imported: null,
          importedCount: 0,
          ignored: null,
          ignoredCount: 0,
          hash: '',
          nextIndex: null,
          note: ''
        },
        accounts: {
          imported: null,
          importedCount: 0,
          ignored: null,
          ignoredCount: 0,
          hash: '',
          nextIndex: null,
          note: ''
        },
        projects: {
          imported: null,
          importedCount: 0,
          ignored: null,
          ignoredCount: 0,
          hash: '',
          nextIndex: null,
          note: ''
        }
      },
      importTemplate: {
        users: [
          {
            username: 'demoUser',
            givenName: 'givenName - Vorname',
            surname: 'surname - Nachname',
            email: 'demo.nutzer@fau.de',
            enabled: true,
            localAuthorities: ['user', 'admin'],
            hasGivenConsent: false,
            hasGivenTouconsent: false,
            touconsentDate: null
          }
        ],
        accounts: [
          {
            vorlage: 'FAU - Known User',
            daten: {
              uid: '20000 : REQUIRED',
              username: 'demo100h : REQUIRED',
              homeDirectory: '/home/hpc/demoProject/demo100h',
              shell: '/bin/bash',
              project: 'demoProject : REQUIRED',
              user: {
                username: 'demoUser : REQUIRED'
              },
              state: 'active',
              prefix: 'demo'
            }
          },
          {
            vorlage: 'FAU - New User',
            daten: {
              uid: '20000 : REQUIRED',
              username: 'demo100h : REQUIRED',
              homeDirectory: '/home/hpc/demoProject/demo100h',
              shell: '/bin/bash',
              project: 'demoProject : REQUIRED',
              user: {
                username: 'demoUser : REQUIRED',
                givenName: 'givenName - Vorname : REQUIRED',
                surname: 'surname - Nachname : REQUIRED',
                email: 'demo.nutzer@fau.de : REQUIRED',
                enabled: true,
                localAuthorities: ['user'],
                hasGivenConsent: false,
                hasGivenTouconsent: false,
                touconsentDate: null
              },
              state: 'active',
              prefix: 'demo'
            }
          },
          {
            vorlage: 'NHR',
            daten: {
              uid: '20001 : REQUIRED',
              username: 'a100bc10 : REQUIRED',
              homeDirectory: '/home/nhr/a100bc/a100bc10',
              shell: '/bin/bash',
              project: 'a100bc : REQUIRED',
              user: {
                username: 'demoUser'
              },
              state: 'active'
            }
          }
        ],
        projects: [
          {
            vorlage: 'FAU',
            daten: {
              title: 'Demo FAU project',
              prefix: 'demo',
              projectId: 'demoProject',
              province: 'BY',
              projectType: 101,
              unixGroupId: 1000,
              unixGroupName: 'demo',
              state: 'active',
              validFrom: '1999-12-31T23:00:00Z',
              validUntil: '2030-12-30T23:00:00Z',
              accessibleFrom: '2021-01-01T23:00:00Z',
              accessibleUntil: '2021-12-30T23:00:00Z',
              organization: 'https://www.sso.uni-erlangen.de/simplesaml/saml2/idp/metadata.php',
              domain: 20102,
              pi: 'demoUser',
              managers: [
                'demoUser'
              ],
              computeResources: [],
              storageResources: []
            }
          },
          {
            vorlage: 'NHR',
            daten: {
              title: 'Demo NHR project',
              projectId: 'a100bc',
              province: 'HB',
              projectType: 103,
              unixGroupId: 1000,
              unixGroupName: 'a100bc',
              state: 'active',
              validFrom: '1999-12-31T23:00:00Z',
              validUntil: '2030-12-30T23:00:00Z',
              accessibleFrom: '2021-01-01T23:00:00Z',
              accessibleUntil: '2021-12-30T23:00:00Z',
              organization: 'https://idp.mpi-bremen.de/idp/shibboleth',
              domain: 20106,
              pi: 'demoUser',
              managers: [
                'demoUser'
              ],
              computeResources: [],
              storageResources: []
            }
          }
        ]
      }
    }
  },
  beforeMount () {
  },
  methods: {
    parseJson (file, entity) {
      const reader = new FileReader()
      reader.readAsText(file)
      reader.onload = () => {
        try {
          this.importJson[entity] = JSON.parse(reader.result.toString())
          this.$bvModal.show(`import-json-preview-${entity}`)
        } catch (error) {
          this.makeToast(
            this.$i18n.t('comp.import.jsonParseError.text', { error: error }),
            this.$i18n.t('comp.import.jsonParseError.title'),
            'danger'
          )
        }
      }
    },
    clearFileUpload (entity) {
      this.importFile[entity] = null
      this.importJson[entity] = []
    },
    hideModalAndClearFileUpload (entity) {
      this.$bvModal.hide(`import-json-preview-${entity}`)
      this.clearFileUpload(entity)
    },
    onSubmit (entity) {
      const params = { entity: entity }
      importServiceForAdminView.create(this.importJson[entity], params).then(
        response => {
          this.hideModalAndClearFileUpload(entity)
          this.importResult[entity].imported = response.imported
          this.importResult[entity].importedCount = response.imported.length
          this.importResult[entity].ignored = response.ignored
          this.importResult[entity].ignoredCount = response.ignored.length
          this.importResult[entity].hash = response.hash
          this.importResult[entity].note = response.note
          if (entity === 'accounts') {
            this.importResult[entity].nextIndex = response.nextIndex
          }
          this.$bvModal.show(`import-result-${entity}`)
        }
      ).catch(
        error => {
          this.hideModalAndClearFileUpload(entity)
          console.log(error)
          this.makeToast(
            this.$i18n.t('comp.import.error.text', { status: error.status, message: error.message }),
            this.$i18n.t('comp.import.error.title'),
            'danger'
          )
        }
      )
    },
    joinImported (entity) {
      if (this.importResult[entity] && this.importResult[entity].importedCount > 0) {
        return this.importResult[entity].imported.join(', ')
      } else {
        return false
      }
    },
    joinIgnored (entity) {
      if (this.importResult[entity] && this.importResult[entity].ignoredCount > 0) {
        return this.importResult[entity].ignored.join(', ')
      } else {
        return false
      }
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>
.collapsed > .when-closed,
.not-collapsed > .when-open {
  float: right;
}
.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
